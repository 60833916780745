import { Component } from 'react';

import { ARTISEE_ID_URL } from '../../config';

class JoinPopup extends Component {
  componentDidMount() {
    const callbackUrl = encodeURI(`${window.location.origin}/callback/web/join/`);
    window.location.replace(`${ARTISEE_ID_URL}/join?callbackUrl=${callbackUrl}&appYn=N&logoYn=Y`);
  }

  render() {
    return null;
  }
}

export default JoinPopup;
